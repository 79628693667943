import {Component, ElementRef, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import {ABetsnapdetailComponent} from '../a-betsnapdetail-component';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AuthenticationService,
    BetsnapdetailService,
    ChatService, ErrorService,
    GameBetPlacementOptionEnum,
    GoogleAnalyticsService, MyModalService,
    MyNotificationsService, OneSignalService,
    TenantImageTypeEnum,
    TenantService, UrlService,
    WindowRef
} from '../../../../shared';
import {AngularPageVisibilityService} from 'angular-page-visibility-v2';
import {TranslateService} from '@ngx-translate/core';
import {FeedService} from '../../../../shared/services/feed.service';
import * as moment from 'moment';
import {take, takeWhile} from 'rxjs/operators';
import {
    AppHttpResponsesBetsnapsGamesGameHttpResponse as GameHttpResponse,
    AppHttpResponsesBetsnapsGamesGameLeaderboardUserHttpResponse as LeaderboardUserHttpResponse
} from '../../../../api';
import {NotificationType} from 'angular2-notifications';
import {HttpErrorResponse} from '@angular/common/http';
import {ModalSize, ModalTemplate, TemplateModalConfig} from '@aligorji/ngx-fomantic-ui';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'betsnaps-betsnapdetail-v3',
    templateUrl: './betsnapdetail-v3.component.html',
    styles: [`

    `]
})
export class BetsnapdetailV3Component extends ABetsnapdetailComponent {

    @ViewChild('betsnapDetailWrap') protected betsnapDetailWrap: ElementRef;
    @ViewChild('modalJoinedTemplate') modalJoinedTemplate: ModalTemplate<null, string, string>;
    @ViewChild('modalJoinApproveTemplate') modalJoinApproveTemplate: ModalTemplate<null, string, string>;

    public modalJoined = null;
    public modalJoinApprove = null;

    public joinModalImage: string;
    public loginModalImage: string;

    public backgroundImage: string = '';
    public secondsToStart: number;

    private scrollBorder = 96;

    public headerSize = 'big';

    private backPath = '/arena';
    public showPrizes: boolean = true;
    public battleRoyalMissingMarkets: boolean = false;
    public processJoin: boolean = false;
    public joinApproveVisible: boolean = false;
    public showLoader: boolean = false;

    public gameNotificationsForm: UntypedFormGroup = new UntypedFormGroup({
        game_notifications_not_joined: new UntypedFormControl('1')
    });

    constructor(@Inject(DOCUMENT) protected document: any,
                protected renderer: Renderer2,
                protected activatedRoute: ActivatedRoute,
                protected router: Router,
                protected route: ActivatedRoute,
                public tenantService: TenantService,
                protected authenticationService: AuthenticationService,
                public betsnapdetailService: BetsnapdetailService,
                protected chatService: ChatService,
                protected pageVisibilityService: AngularPageVisibilityService,
                protected translateService: TranslateService,
                protected myNotificationsService: MyNotificationsService,
                public googleAnalyticsService: GoogleAnalyticsService,
                protected feedService: FeedService,
                protected myModalService: MyModalService,
                protected errorService: ErrorService,
                protected oneSignalService: OneSignalService,
                protected windowRef: WindowRef,
                private urlService: UrlService) {
        super(
            document,
            renderer,
            activatedRoute,
            router,
            tenantService,
            authenticationService,
            betsnapdetailService,
            chatService,
            pageVisibilityService,
            translateService,
            myNotificationsService,
            googleAnalyticsService,
            feedService,
            windowRef
        );

        this.gameUpdated$.pipe(takeWhile(() => this.componentAlive))
            .subscribe((gameUpdated: boolean) => {
                if (gameUpdated && this.game) {
                    this.checkSecondsToStart(this.game);

                    if (this.game.bgimg_game_details) {
                        this.backgroundImage = 'url("' + this.game.bgimg_game_details + '")';
                    } else if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_GAME_DETAILS)) {
                        this.backgroundImage = 'url("' + this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.DEFAULT_GAME_DETAILS).media_url + '")';
                    }

                }
            });

        if (this.urlService.getPreviousUrl()) {
            this.backPath = this.urlService.getPreviousUrl();
        }

        this.betsnapdetailService.game$
            .pipe(takeWhile(() => this.componentAlive))
            .subscribe(
                (gameHttpResponse: GameHttpResponse) => {
                    if (gameHttpResponse) {
                        if (localStorage.getItem('join-force-' + gameHttpResponse.game_unique_id)) {
                            if (this.authenticationService.currentUser &&
                                !gameHttpResponse.is_current_user_joined) {
                                this.game = gameHttpResponse;
                                this.joinGame();
                            }
                            localStorage.removeItem('join-force-' + gameHttpResponse.game_unique_id);
                        }
                        this.showLoader = false;
                    }
                }
            );
    }

    ngOnInit() {
        super.ngOnInit();

        // set join widget image
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.JOIN_MODAL_IMAGE)) {
            this.joinModalImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.JOIN_MODAL_IMAGE).media_url;
        } else {
            this.joinModalImage = './assets/img/img_winner.png';
        }

        // set login widget image
        if (this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE)) {
            this.loginModalImage = this.tenantService.getTenantImageMediaTranslationForLanguage(TenantImageTypeEnum.LOGIN_MODAL_IMAGE).media_url;
        } else {
            this.loginModalImage = './assets/img/i-login-circle.png';
        }
    }

    private checkSecondsToStart(game: GameHttpResponse) {
        const gameStartDate = moment(game.start_date);
        this.secondsToStart = gameStartDate.diff(moment(), 'seconds', false);
    }

    public isRankingAccessible(): boolean {
        return (
            !!this.currentUser
            && (this.game.is_current_user_joined || this.game.competition_type !== 3)
            && !(this.game.sport_id === 999 && this.game.size === 1)
        );
    }

    public navigateBack() {
        this.router.navigate([this.backPath]);
        return;
    }

    private setHeaderOnScroll() {
        if (this.betsnapDetailWrap && this.betsnapDetailHeader && this.game && !this.processData) {
            const betsnapDetailWrap = this.betsnapDetailWrap.nativeElement;
            const betsnapDetailHeader = this.betsnapDetailHeader.nativeElement;

            if (this.currentScrollPosition >= this.scrollBorder) {
                this.renderer.addClass(betsnapDetailWrap, 'header-onscroll');
                this.renderer.addClass(betsnapDetailHeader, 'header-onscroll');

                this.headerSize = 'small';
            } else {
                this.renderer.removeClass(betsnapDetailWrap, 'header-onscroll');
                this.renderer.removeClass(betsnapDetailHeader, 'header-onscroll');

                this.headerSize = 'big';
            }
        }
    }

    protected setSideBarSticky() {
        if (this.betsnapDetailContent && this.betsnapDetailHeader && this.game && !this.processData) {
            const betsnapDetailContent = this.betsnapDetailContent.nativeElement;
            const betsnapDetailHeader = this.betsnapDetailHeader.nativeElement;

            if (this.currentScrollPosition >= this.scrollBorder &&
                betsnapDetailContent.offsetHeight > (this.nativeWindow.window.innerHeight * 1.33) &&
                this.windowWidth > 767 &&
                (
                    betsnapDetailContent.offsetHeight > this.leftSidebarSticky.nativeElement.offsetHeight &&
                    betsnapDetailContent.offsetHeight > this.rightSidebarSticky.nativeElement.offsetHeight
                )
            ) {
                this.sidebarSticky = true;
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'position', 'fixed');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'position', 'fixed');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'top', (betsnapDetailHeader.offsetHeight + 66) + 'px');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'top', (betsnapDetailHeader.offsetHeight + 66) + 'px');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'left', this.leftSidebar.nativeElement.getBoundingClientRect().left + 'px');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'left', this.rightSidebar.nativeElement.getBoundingClientRect().left + 'px');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'width', this.leftSidebar.nativeElement.offsetWidth + 'px');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'width', this.rightSidebar.nativeElement.offsetWidth + 'px');
            } else {
                this.sidebarSticky = false;
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'position', 'static');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'position', 'static');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'top', 'auto');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'top', 'auto');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'left', 'auto');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'left', 'auto');
                this.renderer.setStyle(this.leftSidebarSticky.nativeElement, 'width', 'auto');
                this.renderer.setStyle(this.rightSidebarSticky.nativeElement, 'width', 'auto');
            }
        }
    }

    @HostListener('window:resize', [])
    @HostListener('window:orientationchange', [])
    @HostListener('window:scroll', [])
    betsnapDetailListeners() {
        this.currentScrollPosition = Math.floor(
            this.nativeWindow.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
        );
        this.windowWidth = this.nativeWindow.window.innerWidth;
        this.windowHeight = this.nativeWindow.window.innerHeight;
        this.setHeaderOnScroll();
        this.setSideBarSticky();
    }

    protected refreshGameData() {
        this.betsnapdetailService.refreshGameData(true, true, true, true, true, true, true, true, true, true);
    }

    public isBettingAllowed(): boolean {
        return ((this.game.game_state < 3 && this.game.bet_placement_option !== GameBetPlacementOptionEnum.ONLY_LIVE)
            || (this.game.game_state === 3 && (this.game.bet_placement_option !== GameBetPlacementOptionEnum.ONLY_PRE_GAME)));
    }

    public getGameUser(): LeaderboardUserHttpResponse {
        const gameUser = this.game?.game_user;

        if (this.game.competition_type === 3 && this.game?.game_user?.game_user_group) {
            gameUser.rank = this.game?.game_user?.game_user_group.rank;
            gameUser.is_winner = this.game?.game_user?.game_user_group.is_winner;
            gameUser.points_in_lead = this.game?.game_user?.game_user_group.points_in_lead;
            gameUser.points_to_first = this.game?.game_user?.game_user_group.points_to_first;
            gameUser.points_to_prizes = this.game?.game_user?.game_user_group.points_to_prizes;
            gameUser.prize_amount = this.game?.game_user?.game_user_group.prize_amount;
            gameUser.rank_change_down = this.game?.game_user?.game_user_group.rank_change_down;
            gameUser.rank_change_up = this.game?.game_user?.game_user_group.rank_change_up;
            gameUser.tenant_prize_id = this.game?.game_user?.game_user_group.tenant_prize_id;
        }

        return gameUser;
    }

    approveJoin() {
        if (!this.processJoin) {
            if (this.currentUser && !this.game.is_current_user_joined) {
                if (!this.currentUser.username || !this.currentUser.is_username_confirmed) {
                    this.myModalService.openUsernameInputModal().onApprove(() => this.approveJoin());
                } else {
                    if (this.currentUser.wallets[0].balance < this.game.entry_fee) {
                        this.translateService.get(['GENERAL.LABELS.alert', 'GENERAL.GAMES.insufficient_amount_to_join'])
                            .pipe(take(1)).subscribe(
                            translation => {
                                this.myNotificationsService.createNotificationToast(translation['GENERAL.LABELS.alert'], translation['GENERAL.GAMES.insufficient_amount_to_join'], NotificationType.Alert);
                            });
                    } else {
                        if (this.game.confirm_join_process) {
                            this.showApproveJoin();
                        } else {
                            this.joinGame();
                        }
                    }
                }
            } else {
                const loginRegisterModal = this.myModalService.openLoginRegisterModal('login', null, true, 'game - details');
                if (loginRegisterModal) {
                    loginRegisterModal.onApprove(() => {
                        this.showLoader = true;
                        localStorage.setItem('join-force-' + this.game.game_unique_id, 'true');
                        this.betsnapdetailService.getGameData(this.game.game_unique_id);
                    });
                }
            }
        }
    }

    public joinGame() {
        if (!this.processJoin) {
            this.processJoin = true;

            const subscribeForGameNotifications = (
                this.authenticationService.currentUser && this.game &&
                this.gameNotificationsForm &&
                this.gameNotificationsForm.value.game_notifications_not_joined &&
                this.oneSignalService.webPushAvailableForTenant
            );

            this.betsnapdetailService.joinGame(this.game, this.authenticationService.currentUser.id, subscribeForGameNotifications)
                .pipe(take(1))
                .subscribe({
                    next: (leaderboardUserHttpResponse: LeaderboardUserHttpResponse) => {
                        this.hideApproveJoin();
                        this.processJoin = false;

                        if (subscribeForGameNotifications) {
                            this.oneSignalService.subscribeForWebPushNotifications();
                        }

                        if (this.tenantService.tenantData.configuration.show_join_success_modal && !this.betsnapdetailService.widgetBets) {
                            this.openModalJoined();
                        } else {
                            this.translateService.get([
                                'GENERAL.GAMES.join-modal_heading',
                                'GENERAL.GAMES.join-modal_firsttextpart',
                                'GENERAL.GAMES.join-modal_secondtextpart'
                            ])
                                .pipe(takeWhile(() => this.componentAlive))
                                .subscribe(
                                    translation => {
                                        this.myNotificationsService.createNotificationToast(
                                            translation['GENERAL.GAMES.join-modal_heading'],
                                            translation['GENERAL.GAMES.join-modal_firsttextpart'] + ' ' + translation['GENERAL.GAMES.join-modal_secondtextpart'],
                                            NotificationType.Success
                                        );
                                    });

                            if (this.betsnapdetailService.widgetBets && Object.keys(this.betsnapdetailService.widgetBets).length > 0) {
                                this.router.navigate(['bets'], {relativeTo: this.route});
                                return;
                            } else {
                                if (this.game.sport_id === 999) {
                                    this.router.navigate(['games'], {relativeTo: this.route});
                                } else {
                                    this.router.navigate(['matches'], {relativeTo: this.route});
                                }
                                return;
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        if (err.error.error === 'game_join_game_battle_royal_missing_markets') {
                            this.battleRoyalMissingMarkets = true;
                        }
                        this.hideApproveJoin();
                        this.processJoin = false;
                        this.errorService.handleHttpErrorResponse(err);
                    }
                });
        }
    }

    public openModalJoined() {
        const modalJoinedConfig = new TemplateModalConfig<null, string, string>(this.modalJoinedTemplate);
        modalJoinedConfig.size = ModalSize.Tiny;
        this.modalJoined = this.myModalService.openModal(modalJoinedConfig);
    }

    private openJoinApproveModal() {
        const modalJoinApproveConfig = new TemplateModalConfig<null, string, string>(this.modalJoinApproveTemplate);
        modalJoinApproveConfig.size = ModalSize.Tiny;
        this.modalJoinApprove = this.myModalService.openModal(modalJoinApproveConfig);
    }

    protected showApproveJoin() {
        if (this.game.entry_fee !== 0) {
            this.openJoinApproveModal();
        } else {
            this.joinGame();
        }
    }

    protected hideApproveJoin() {
        if (this.modalJoinApprove) {
            this.modalJoinApprove.approve();
        }
    }

    public modalRedirect(link: string, modal = null) {
        if (modal) {
            modal.approve();
        }
        this.router.navigate([link], {relativeTo: this.route});
        return;
    }
}
